import { AbstractCfnEcmObject, CfnEcmObjectInterface } from './cfnecm-object.model';

export interface DocumentInterface extends CfnEcmObjectInterface {
    /**
     * File name
     */
    fileName: string;
    /**
     * File size.
     */
    size: number;

    /**
     * Media type.
     */
    mediaType?: string;

    /**
     * Preview img path.
     */
    previewUrl?: string;

    /**
     * File url.
     */
    url?: string;

    /**
     * Type mime
     */
    mime: string;

    /**
     * ContentStream Hash
     */
    contentStreamHash: string[];
}

export class Document extends AbstractCfnEcmObject implements DocumentInterface {

    /**
     * File name
     */
    fileName: string;
    /**
     * File size.
     */
    size: number;

    /**
     * Media type.
     */
    mediaType?: string;

    /**
     * Preview img path.
     */
    previewUrl?: string;

    /**
     * File url.
     */
    url?: string;

    /**
     * Type mime
     */
    mime: string;

    /**
     * ContentStream Hash
     */
    contentStreamHash: string[];

    /**
     * File constructor.
     *
     * @param jsonData File
     */
    constructor(jsonData: DocumentInterface) {
        super(jsonData);
        this.fileName = jsonData.fileName;
        this.size = jsonData.size;
        this.mediaType = jsonData.mediaType;
        this.previewUrl = jsonData.previewUrl;
        this.url = jsonData.url;
        this.mime = jsonData.mime;
        this.contentStreamHash = jsonData.contentStreamHash
        if (this.mime) {
            switch (this.mime) {
                case "application/pdf":
                    this.iconUrl = "pdf-file.svg";
                    break;
                case "image/png":
                case "image/jpeg":
                case "image/svg+xml":
                    this.iconUrl = "picture-file.svg";
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    this.iconUrl = "docx-file.svg";
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    this.iconUrl = "xlsx-file.svg";
                    break;
                default:
                    this.iconUrl = "unknown-file.svg";
                    break;

            }
        }
    }
}
