import { MetadataInterface } from './metadata.interface';

export enum CfnEcmObjectTypeEnum {
    securedFolder = 'smvlt:securedFolder',
    securedDocument = 'smvlt:securedDocument',
}

export interface CfnEcmObjectInterface {
    /**
     * Object id.
     */
    id: string;

    /**
     * Object name.
     */
    name: string;

    /**
     * Object type.
     */
    type: CfnEcmObjectTypeEnum;

    /**
     * Object Metadata
     */
    metadata: MetadataInterface;

    /**
     * Object Parent.
     */
    parent?: CfnEcmObjectInterface;

    /**
     * Object date.
     */
    creationDate: number;

    /**
     * Object by.
     */
    createdBy: string;

    /**
     * Last modification date.
     */
    lastModificationDate?: number;

    /**
     * Modified by.
     */
    modifiedBy?: string;

    iconName?: string;
    iconUrl?: string;

    hasMetadata(): boolean;

}

export class AbstractCfnEcmObject implements CfnEcmObjectInterface {
    /**
     * Object id.
     */
    id: string;

    /**
     * Object name.
     */
    name: string;

    /**
     * Object Metadata
     */
    metadata: MetadataInterface;

    /**
     * Object type.
     */
    type: CfnEcmObjectTypeEnum;

    /**
     * Object Parent.
     */
    parent?: CfnEcmObjectInterface;

    /**
     * Creation date.
     */
    creationDate: number;

    /**
     * Created by.
     */
    createdBy: string;

    /**
     * Last modification date.
     */
    lastModificationDate?: number;

    /**
     * Modified by.
     */
    modifiedBy?: string;

    iconName?: string;
    iconUrl?: string;

    /**
     *
     * @param jsonData document
     */

    constructor(jsonData: CfnEcmObjectInterface) {
        this.id = jsonData.id;
        this.name = jsonData.name;
        this.type = jsonData.type;
        this.parent = jsonData.parent;
        this.creationDate = jsonData.creationDate;
        this.createdBy = jsonData.createdBy;
        this.lastModificationDate = jsonData.lastModificationDate;
        this.modifiedBy = jsonData.modifiedBy;
        this.metadata = jsonData.metadata;
    }

    hasMetadata(): boolean {
        return this.metadata && Array.isArray(Object.keys(this.metadata)) && Object.keys(this.metadata).length > 0;
    }

}
