import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { AuthorizationFrontendService, UserFrontendService } from '@numen/smex-lib-common-frontend';
import { CfnEcmFrontConfigurationService } from './cfnecmfront-configuration.service';
import {
    CfnEcmAuthorizations,
    DocumentInterface,
    FolderInterface,
    SmartGedPermissions,
    UserSpace
} from '../models';
import { Router } from '@angular/router';
import { DocumentService } from './document.service';


const AUTHORIZATIONS_PERMISSIONS_MAP = new Map<string, string[]>();
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.APPLY_SPACE_ACL, [SmartGedPermissions.WRITE_ACL]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.APPLY_GROUP_ACL, [SmartGedPermissions.WRITE_ACL]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.APPLY_USER_ACL, [SmartGedPermissions.WRITE_ACL]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.CREATE_DOCUMENT,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.CREATE_FOLDER,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.CREATE_GROUP,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.CREATE_USER,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.CREATE_SPACE,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.DELETE_DOCUMENT,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.DELETE]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.DELETE_FOLDER,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.DELETE]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.DELETE_GROUP,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.DELETE]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.DELETE_USER,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.DELETE]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.DELETE_SPACE,
    [SmartGedPermissions.WRITE_CHILDREN, SmartGedPermissions.DELETE]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_DOCUMENT, [SmartGedPermissions.READ_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_DOCUMENT_PROPERTIES, [SmartGedPermissions.READ_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_DOCUMENT_BINARY, [SmartGedPermissions.READ_CONTENT]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.GET_DOCUMENT_FULL,
    [SmartGedPermissions.READ_CONTENT, SmartGedPermissions.READ_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_FOLDER_CHILDREN, [SmartGedPermissions.READ_CHILDREN]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_FOLDER_PROPERTIES, [SmartGedPermissions.READ_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_GROUP_ACL, [SmartGedPermissions.READ_ACL]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_GROUP_PROPERTIES, [SmartGedPermissions.READ_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_USER_ACL, [SmartGedPermissions.READ_ACL]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.GET_USER_PROPERTIES, [SmartGedPermissions.READ_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.INFO_SPACE, [SmartGedPermissions.READ_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.UNLOCK_USER, [SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.UPDATE_DOCUMENT_PROPERTIES,
    [SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.UPDATE_FOLDER_PROPERTIES,
    [SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(
    CfnEcmAuthorizations.UPDATE_GROUP_PROPERTIES,
    [SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.UPDATE_USER_PASSWORD, [SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.UPDATE_USER_ACTIVATION, [SmartGedPermissions.WRITE_PROPERTIES]);
AUTHORIZATIONS_PERMISSIONS_MAP.set(CfnEcmAuthorizations.UPDATE_USER_PROPERTIES, [SmartGedPermissions.WRITE_PROPERTIES]);

@Injectable({
    providedIn: 'root'
})
export class CfnEcmUserService {
    private static AVAILABLE_SPACES = 'AVAILABLE_SPACES';
    private static LAST_USED_SPACE_KEY = 'LAST_USED_SPACE_KEY';
    private static LAST_SELECTED_DOCUMENT = 'LAST_SELECTED_DOCUMENT';
    private static PARENT_FOLDER_FOR_SEARCH = 'PARENT_FOLDER_FOR_SEARCH';

    public actionInProgress: boolean = false;

    constructor(
        private userService: UserFrontendService,
        private m_AuthorizationService: AuthorizationFrontendService,
        private m_ConfigurationService: CfnEcmFrontConfigurationService,
        private documentService: DocumentService,
        private router: Router) {
        this.router.events.subscribe(e => {
            this.documentService.handleRouteChange();
        })
    }

    public setUserSpaces(spaces: UserSpace[]) {
        window.localStorage.setItem(CfnEcmUserService.AVAILABLE_SPACES, JSON.stringify(spaces));
    }

    public getUserSpaces(): UserSpace[]|null {
        const userData = this.userService.userData as any;
        if (Array.isArray(userData?.securedSpaces)) {
            userData.securedSpaces.filter((s: UserSpace) => s.authorizations === undefined)
                    .forEach((securedSpace: UserSpace) => this.computeSpaceAuthorizations(securedSpace));
            return userData?.securedSpaces as UserSpace[];
        }
        return null;
    }

    /**
     * Get list of spaces.
     *
     * @returns observable of space.
     */
    public getAvailableSpaces(): Observable<UserSpace[]> {
        let URI: string = this.m_ConfigurationService.getCfnEcmApiUri('/spaces/available');
        return from(this.m_AuthorizationService.get<UserSpace[]>(URI));
        // const spaces = await this.m_AuthorizationService.get<Space[]>(URI);
        // this.setAvailableSpaces(spaces);
        // return spaces;
    }

    /**
     * Get last used space.
     */
    public get lastUsedSpace(): UserSpace | null {
        const space = window.localStorage.getItem(CfnEcmUserService.LAST_USED_SPACE_KEY);
        return space ? JSON.parse(space) : null;
    }

    /**
     * Set current space.
     *
     * @param space space.
     */
    public setLastUsedSpace(space: UserSpace): void {
        window.localStorage.setItem(CfnEcmUserService.LAST_USED_SPACE_KEY, JSON.stringify(space));
    }

    public setSelectedDocument(document: DocumentInterface) {
        window.localStorage.setItem(CfnEcmUserService.LAST_SELECTED_DOCUMENT, JSON.stringify(document));
    }

    public getSelectedDocument(): DocumentInterface | null {
        const doc = window.localStorage.getItem(CfnEcmUserService.LAST_SELECTED_DOCUMENT);
        return doc ? JSON.parse(doc) : null;
    }

    public setParentFolderForSearch(folder: FolderInterface) {
        window.localStorage.setItem(CfnEcmUserService.PARENT_FOLDER_FOR_SEARCH, JSON.stringify(folder));
    }

    public getParentFolderForSearch(): FolderInterface | null {
        const folder = window.localStorage.getItem(CfnEcmUserService.PARENT_FOLDER_FOR_SEARCH);
        return folder ? JSON.parse(folder) : null;
    }

    hasAuthorization(authorization: string): boolean {
        const auths = this.lastUsedSpace?.authorizations;
        if (auths) {
            return auths.includes(authorization);
        }
        return this.m_AuthorizationService.hasThisAuthorization(authorization);
    }

    public close() {
        window.localStorage.removeItem(CfnEcmUserService.LAST_USED_SPACE_KEY);
        window.localStorage.removeItem(CfnEcmUserService.AVAILABLE_SPACES);
        window.localStorage.removeItem(CfnEcmUserService.LAST_SELECTED_DOCUMENT);
        window.localStorage.removeItem(CfnEcmUserService.PARENT_FOLDER_FOR_SEARCH);
    }

    private computeSpaceAuthorizations(space: UserSpace) {
        if (space.authorizations !== undefined) {
            return;
        }
        const permissions = space.permissions;
        const auths = this.userService.userData?.authorizations;
        if (permissions && auths) {
            const effectiveAuths = [];
            for (const auth of auths) {
                const permNeeded = AUTHORIZATIONS_PERMISSIONS_MAP.get(auth);
                if (!Array.isArray(permNeeded) || permNeeded.every(p => permissions.includes(p))) {
                    effectiveAuths.push(auth);
                }
            }
            if (effectiveAuths.length > 0) {
                space.authorizations = effectiveAuths;
                return;
            }
        }
        space.authorizations = null;
    }

}
