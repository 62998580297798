import { Injectable } from '@angular/core';
import { Data, Router } from '@angular/router';
import { Observable, from, of, map, firstValueFrom } from 'rxjs';

import { AuthorizationFrontendService } from '@numen/smex-lib-common-frontend';
import { CfnEcmFrontConfigurationService } from './index';
import { Document, DocumentInterface } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class DocumentService {

    abortController: AbortController | null = null

    constructor ( private m_AuthorizationService : AuthorizationFrontendService,
                  private m_ConfigurationService : CfnEcmFrontConfigurationService) {
    }

    /**
    *  Get document properties by Id.
    *
    * @param spaceId    L’identifiant de l’espace.
    * @param docId      L’identifiant du document à récupérer.
    * @returns Le document.
    */
    public async getDocument(spaceId: string, docId: string): Promise<Document | null> {
        if ((!spaceId) || (!docId)) {
            return null;
        }

        const uri: string = this.m_ConfigurationService.getCfnEcmApiUri(`/documents/${spaceId}/${docId}/properties`);
        const doc = await this.m_AuthorizationService.get<DocumentInterface | undefined>(uri);
        if (doc) {
            return new Document(doc);
        }
        return null;
    }

    /**
    * Get document binary.
    *
    * @param spaceId    L’identifiant de l’espace.
    * @param docId      L’identifiant du document à récupérer.
    * @returns
    */
    public getDocumentBinary(spaceId: string, docId: string): Observable<Blob> {
        if ((!spaceId) || (!docId)) {
            return of();
        }

        const uri: string = this.m_ConfigurationService.getCfnEcmApiUri(`/documents/${spaceId}/${docId}/binary`);
        this.abortController = new AbortController();

        const request = from(
            fetch(uri, {
                headers: {
                    "Authorization": "Bearer " + (window.localStorage.getItem("IdentityAccessToken"))
                },
                signal: this.abortController.signal,
            }).then(res => {
                this.abortController = null;
                return res.blob();
            })
        );

        return request;
    }

    public handleRouteChange() {
        if(this.abortController) {
            this.abortController.abort();
            this.abortController = null;
        }
    }

    /**
    * Rename document.
    *
    * @param documentId
    * @param name
    * @param metadata
    * @returns updated document.
    */
    public updateDocument(documentId: string, name?: string, metadata?: any): Observable<Document> {
        const uri: string = this.m_ConfigurationService.getCfnEcmApiUri(`/documents/${documentId}`);
        const body: any = {};
        if (name) {
            body.name = name;
        }
        if (metadata) {
            body.data = metadata;
        }
        return from(this.m_AuthorizationService.patch<DocumentInterface>(uri, body))
            .pipe(map(doc => new Document(doc)));
    }

    /**
     * Créer un document.
     */
    public createDocument(file: Blob, name: string, spaceId: string, parentFolderId?: string): Observable<Document> {
        // Le type de média dans le blob semble être perdu en transfèrt vers le backend. On le ressort
        // donc et on le passe en propriétés pour pouvoir le garder.
        const properties: Data = {
            spaceId,
            name,
            'mediatype': file.type
        };
        if (parentFolderId) {
            properties['parentFolderId'] = parentFolderId;
        }

        const formData = new FormData();
        formData.append('content', file);
        formData.append('properties', JSON.stringify(properties));

        const uri: string = this.m_ConfigurationService.getCfnEcmApiUri('/documents');
        return from(this.m_AuthorizationService.post<DocumentInterface>(uri, formData))
            .pipe(map(doc => new Document(doc)));
    }

    /**
     * Delete a document.
     *
     * @param documentId string
     */
    public deleteDocument(documentId: string): Promise<void> {
        return this.m_AuthorizationService.delete(
            this.m_ConfigurationService.getCfnEcmApiUri(`/documents/${documentId}`));
    }
}
